.slick_box{
    width: 100%;
    margin: 4rem 0;
    text-align: center;
}


.slick_box img{
    width: 100%;
    height: 310px;
    padding: 0 10px;
}

@media screen and (max-width: 940px) {
    .slick_box{
        width: 100%;
        margin: 1rem 0;
        text-align: center;
        padding: 1rem;
    }
}