@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul{
  list-style-type: none;
}

a{
  text-decoration: none;
  color:#333;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container_app{
  max-width: 1400px;
  margin: auto;

  min-height: 100vh;
}

