.search {
    background-color: rgba(0,0,0,.5);
    color: white;
    height: 80vh;
    width: 100%;
    position: relative;
    margin-top: 90px;
    margin-bottom: 4rem;
}

.search:before {
    content: '';
    background: url(../../assets/content.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.search .content{
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin-left: 100px;
    padding: 1rem;
}

.dismantling_title{
    font-family: Roboto;
    font-weight: bold;
    font-size: 31px;
    margin-bottom: 1rem;
}

.search .content_title{
    margin: 30px 0 0 0;
}

.search .contacts{
padding: 1rem 0;
}

.search .contacts_title{
    margin-bottom: 8px;
    border-bottom: 1px solid white;
    font-size: 24px;
}

.search .contacts span{
    font-family: Roboto;
    font-weight: bold;
}
.search .contacts_list{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid white;
    font-size: 26px;
    width: 70%;
}

.order_phone{
    color: white;
    font-family: Roboto;
    font-weight: bold;
    margin:10px 0 10px 0px;
}

.order_phone:hover {
    color: #94949b;
}

.contact_icon:hover{
    color: #94949b;
}

.search .list_icons{
    display: inline;
}

.contact_icon {
    color: white;
    margin-left: 10px;
}
.contact_icon_viber {
    color: white;
    margin-left: 10px;
    width: 28px;
    height: 28px;
}

.general_info{
    display: none;
}

.search h1{
    font-size: 2.5rem;
    text-transform: capitalize;
}
.search .text{
    margin: 1.5rem;
    font-size: 1.5rem;
}
.search .search_text{
    margin-top: 4px;
}
.search .description_item{
    margin-bottom: 6px;
}
.search .description_step{
    font-weight: bold;
}

.search_element{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #333;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 4px;
}

.search input[type=text]{
    border: transparent;
    background-color: transparent;
    width: 400px;
    font-size: 1.2rem;
    font-family: 'Releway', sans-serif;
}

.search input[type=text]:focus{
    outline: none;
}

.search label{
    display: flex;
    padding: 0 14px 0 8px;
}

.search button{
    background-image: linear-gradient(45deg, #93c4c9 0%, #c9e7ee 100%);
    border: 1px solid #93c4c9;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
}

.search .radio{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 940px){

    .search {
        height: 100%;
        background-color: rgba(0,0,0,.5);
        color: white;
        width: 100%;
        position: relative;
        margin-top: 90px;
    }

    .search .content{
        margin-left: 10px;
    }
    .search .contacts{
        padding: 0;
    }

    .search .general_info{
        display: flex;
        align-items: center;
        margin-top: 3rem;
        font-family: Roboto;
        font-size: 20px;
    }
    .search .general_info_weather{
        margin-left: 20px;
    }

    .search h1{
        font-size: 2.5rem;
    }
    .search input[type=text]{
        width: 100%;
    }

    .search .contacts_title{
        border-bottom: none;
    }

    .dismantling_title{
        font-family: Roboto;
        font-weight: bold;
        font-size: 31px;
        margin-bottom: 4rem;
    }

    .contacts_title span{
        border-bottom: 1px solid white;
    }
    .search .contacts_list{
        border-bottom: 1px solid white;
        font-size: 30px;
        width: 100%;
    }

    .search .list_icons{
        margin-top: 14px;
        display: block;
    }

    .search .contacts_list{
        border-bottom: none;
    }

    .order_phone{
        border-bottom: 1px solid white;
        width: 88%;
    }

    .search .search_text{
        display: none;
    }
}

.search .icon {
    font-size: 1.5rem;
}