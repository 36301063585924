.order {
    display: flex;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 1rem;
}

.order .order_description {
    padding: 1rem;
    line-height: 22px;
}

.order .description_step {
    font-weight: bold;
}


.order .order_contacts {
    display: flex;
    margin-top: 20px;
}

.order .order_phone {
    display: flex;
    font-weight: bold;
    font-family: Roboto;
}

.order .order_phone:hover {
    color: #75c7d0;
}

.order_number {
    margin-top: 2px;
}

.order .order_icon {
    font-size: 1.5rem;
    margin-left: 6px;
}

.order .order_icon:hover {
    color: #75c7d0;
}

@media screen and (max-width: 940px) {
    .order {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        margin: 0 8px;

    }

    .order .order_description {
        padding: .3rem;
        line-height: 20px;
    }

    .order img {
        width: 100%;
        height: 50%;
    }
}