.options_item {
    width: 262px;
    height: 366px;

    background-color: #d2d2d7;
    margin-bottom: 10px;
}

.options_title {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.item_title {
    font-weight: bold;
    margin-top: 10px;
    padding-bottom: 20px;
    text-align: center;
}

.description_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 6px 6px;
}

.tooltip img {
    position: relative;
    z-index: 2;
    height: 280px;
}

.description_info {
    display: none;

    height: 280px;
    width: 260px;

    text-align: center;
    padding:100px 6px 0;

    position: absolute;
    color: white;
    z-index: 5;

    background-color: rgba(0, 0, 0, .5);
}

.tooltip {
    position: relative;
    cursor: pointer;
}

.tooltip:hover .description_info {
    display: block;
}

.tooltip_info {
    display: none;
    position: absolute;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1;
}