.options_page {
    margin: 90px 0 30px 0;
    /*padding: 0 1.5rem 3rem 1.5rem;*/

    display: flex;
    flex-direction: column;

    /*border: 1px solid green;*/
}

.options_title {
    display: flex;
    justify-content: center;
    margin: 30px;
}

.options_items {
    display: flex;
    justify-content: space-evenly;

    /*border: 1px solid blue;*/
}

.options_item {
    width: 290px;
    background-color: #d2d2d7;

    /*border: 1px solid gray;*/
}

.item_title {
    font-weight: bold;
    margin-top: 10px;
}

@media screen and (max-width: 1240px) {
    .options_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 0 10px;
    }

    .options_item {
        margin-bottom: 30px;
    }
}