.contact_list{
    margin-right: 5rem;
    width: 50%;
}

.contact_list_contacts{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

h3{
    margin-bottom: 14px;
}

.contacts {
    color: #75c7d0;
    display: flex;
    align-items: center;
}
.contacts_viber{
    margin-left: 2px;
    display: flex;
}

.icon {
    color: #75c7d0;
    margin-right: 4px;
    height: 24px;
    width: 24px;
}
.icon_viber{
    color: #75c7d0;
    margin-right: 6px;
    height: 22px;
    width: 21px;
}


.contacts:hover{
    color: #5a5d5e;
}
.icon:hover{
    color: #5a5d5e;
}

.order_number{
    display: flex;
    margin-top: 2px;
    font-weight: bold;
    font-family: Roboto;
}

