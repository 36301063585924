.footer {
    width: 100%;

    padding: 1.2rem  0 0;
    background-color: #d2d2d7;
}

.footer p{
    color: white;
}

.footer .info{
    color: white;
}

.footer .info:hover{
    color: #75c7d0;
}

.footer .container {
    display: flex;
    justify-content: space-around;
}

.footer .col {
    /*margin: auto;*/
    display: flex;
    padding: 1rem;
    flex-direction: column;
    width: 20%;
}

.footer h3 {
    margin-bottom: 14px;
}

.footer p {
    margin: .2rem 0;
}

.footer .social {
    /*text-align: center;*/
}

.footer .downs_block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: #414654;
    color: white;
    padding: 0 3.2rem;
}

@media screen and (max-width: 940px) {
    .footer{
        padding: 0;
    }
    .footer .container {
        /*display: flex;*/
        /*flex-direction: column;*/
        /*margin: 0 10px;*/
        display: none;
    }

    .footer .col {
        width: 100%;
    }

    .footer .downs_block{
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        justify-content: space-between;
        flex-direction: column;
        padding: 10px 3.2rem;
    }
}
